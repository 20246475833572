<template>
  <v-modal title="Produits existants détectés">
    <div class="modal-body">
      <p>
        Vos produits dans votre fichier CSV sont prêts à être synchronisés!
        Par contre, un ou plusieurs de ces produits existent déjà dans votre inventaire.
      </p>
      <p class="d-block pt-3 mb-1 font-weight-bold text-center">
        Voulez-vous synchroniser ces produits existants?
      </p>
      <p class="pb-3 text-center small">Cette action ne peut pas être annulée par la suite.</p>
      <b-form-checkbox
        :checked="shouldOverwrite"
        @change="handleChange"
        class="text-center"
      >
        Oui, je souhaite aussi synchroniser mes produits existants.
      </b-form-checkbox>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" @click="$emit('cancel')">Annuler</button>
      <button type="button" class="btn btn-primary" @click="submit">Confirmer</button>
    </div>
  </v-modal>
</template>

<script>
export default {
  data () {
    return {
      shouldOverwrite: false
    }
  },
  methods: {
    handleChange (value) {
      this.shouldOverwrite = value
    },
    async submit () {
      this.$emit('confirm', this.shouldOverwrite)
    }
  }
}
</script>
